var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v("Sign Up")])],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Sign Up to Integration HomeAssistant ")]),_c('validation-observer',{ref:"registerForm"},[_c('div',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":"johndoe"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false : null,"name":"register-username","placeholder":"johndoe"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:6","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{ref:"password",staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(false)?_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I agree to "),_c('b-link',[_vm._v("privacy policy & terms")])],1)],1):_vm._e(),_c('b-button',{attrs:{"variant":"primary","block":"","type":"button"},on:{"click":_vm.validationForm}},[_vm._v(" Sign up ")])],1)]),(false)?_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account? ")]),_c('b-link',{attrs:{"to":{ name: 'auth-login-v1' }}},[_c('span',[_vm._v("Sign in instead")])])],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }